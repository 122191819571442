import { all, fork } from "redux-saga/effects";

//setting
import EnumsSaga from "./mains/enums/saga";
import SettingsSaga from "./mains/settings/saga";
import SettingsCategorieSaga from "./mains/categories/saga";

import AuthSaga from "./auth/login/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layouts/saga";
import NewsLetterSaga from "./newsLetter/saga";

//randomuser
import RandomSaga from "./auth/randomusername/saga";

//followMember
import followsaga from "./auth/followmember/saga";

//coverprofile
import CoverProfileSaga from "./auth/coverProfile/saga";
import EditGallarySaga from "./auth/editGallary/saga";
import MyProfileSaga from "./auth/myprofile/saga";

//nfts
import DeleteNftSaga from "./nfts/deleteNft/saga";
import NftDetailSaga from "./nfts/details/saga";
import DownloadCertificateSaga from "./nfts/downloadCertificate/saga";
import EditNftSaga from "./nfts/editNft/saga";
import ExploreSaga from "./nfts/explore/saga";
import GetNftHistorySaga from "./nfts/historyNft/saga";
import InspireSaga from "./nfts/inspire/saga";
import NsfwSaga from "./nfts/nsfw/saga";
import RatingNftSaga from "./nfts/ratingNft/saga";
import ReportNftSaga from "./nfts/reportNft/saga";
import UploadNftSaga from "./nfts/upload/saga";

//shippingAddress
import ShippingAddressSaga from "./nfts/shippingAddress/saga";

//Home
import ExclusiveCollectionsSaga from "./home/exclusiveCollections/saga";
import FeaturedArtistsSaga from "./home/featuredArtists/saga";
import HeroSectionSaga from "./home/heroSection/saga";
import HotCollectionsSaga from "./home/hotCollections/saga";
import RecentlySoldSaga from "./home/recentlySold/saga";
import TestimonialSaga from "./home/testimonial/saga";
import TrendingArtworksSaga from "./home/trendingArtworks/saga";

import announcementNewsSaga from "./home/announcementNews/saga";
import communitiesSaga from "./home/communities/saga";
import hotBidsSaga from "./home/hotBids/saga";
import topCollectorsSaga from "./home/topCollectors/saga";
import topCuratorsSaga from "./home/topCurators/saga";
import topPhotographersSaga from "./home/topPhotographers/saga";

import notificationSaga from "./notifications/filter/saga";
import VisitedNotifSaga from "./notifications/markAllRead/saga";
import NotificationSettingsSaga from "./notifications/setting/saga";

import assetPortfolioSaga from "./auth/assetPortfolio/saga";
import referralDashboardSaga from "./auth/referralDashboard/saga";
import museumSaga from "./home/museum/saga";
import newListingSaga from "./home/newListing/saga";
import LastnotificationSaga from "./notifications/lastNotifactions/saga";
import HomeMarketPlaceInfoSaga from "./home/marketPlaceInfo/saga";
import EventsSaga from "./home/events/saga";
import SeedCategoriesSaga from "./home/seedCategories/saga";

//account
import CheckUserNameSaga from "./auth/checkAvailableUserName/saga";
import ReportUserSaga from "./auth/reportUser/saga";
import ToggleLikeSaga from "./auth/toggleFavorites/saga";
import GetUserCollectionSaga from "./auth/userCollection/saga";
import VerificationFormSaga from "./auth/verificationForm/saga";
import WalletBalancesSaga from "./auth/walletBalance/saga";
import DownloadArtistCertificateSaga from "./auth/artistCertificate/saga";

import FollowerSaga from "./auth/followers/saga";
import FollowingSaga from "./auth/following/saga";
import ReferralSaga from "./auth/referrals/saga";
import UserCollectionNftSaga from "./collections/saga";
import TopArtistsSaga from "./home/topArtist/saga";
import museumAuctionSaga from "./museum/Auction/saga";
import museumCalendarSaga from "./museum/Calendar/saga";
import museumCuerrntWeekSaga from "./museum/CurrentWeek/saga";
import museumReleasedSaga from "./museum/Released/saga";
import museumUpcomingSaga from "./museum/Upcoming/saga";
import museumWelcomeSaga from "./museum/Welcome/saga";
import AllNftCardSaga from "./nftCards/all/saga";
import ArchiveNftCardSaga from "./nftCards/archive/saga";
import CollaborationNftCardSaga from "./nftCards/collaboration/saga";
import CollectedNftCardSaga from "./nftCards/collected/saga";
import AccountCollectionNftsSaga from "./nftCards/collections/saga";
import CreatedNftCardSaga from "./nftCards/created/saga";
import FavoritesNftCardSaga from "./nftCards/favorites/saga";
import ManageMyBidsNftCardSaga from "./nftCards/manageMyBids/saga";
import MuseumNftCardSaga from "./nftCards/museum/saga";
import SimillarArtworksSaga from "./nftCards/simillarArtworks/saga";
import MyTransactionsSaga from "./nftCards/transactions/saga";
import OrderConfirmationSaga from "./nfts/orderConfirmation/saga";
import Web3ActionSaga from "./nfts/web3Actions/saga";
import tutorialsSaga from "./tutorials/saga";

//suiWallet
import SuiWalletAccountSaga from "./auth/suiWallet/saga";

//EthWallet
import EthWalletAccountSaga from "./auth/EthWallet/saga";

//GeneralSearch
import GeneralSearchSaga from "./generalSearch/saga";

//LandingPage
import LandingPageSaga from "./landingPage/saga";

//TwitterFarm
import TwitterFarmSaga from "./twitterFarm/saga";
import TwitterPointsSaga from "./twitter/Points/saga";
import TwitterWithdrawalsSaga from "./twitter/Withdrawals/saga";
import TwitterStakesSaga from "./twitter/Stakes/saga";

//twitter-verify
import VerifyTwittersaga from "./twitter/verifyTwitter/saga";

//custom task
import CustomTaskSaga from "./twitter/CustomTask/saga";

export default function* rootSaga() {
  yield all([
    fork(SettingsSaga),
    fork(SettingsCategorieSaga),
    fork(LayoutSaga),
    fork(EnumsSaga),
    fork(AuthSaga),
    fork(MyProfileSaga),
    fork(MuseumNftCardSaga),
    fork(ProfileSaga),
    fork(NewsLetterSaga),
    fork(HeroSectionSaga),
    fork(TrendingArtworksSaga),
    fork(topCuratorsSaga),
    fork(topPhotographersSaga),
    fork(FeaturedArtistsSaga),
    fork(ExclusiveCollectionsSaga),
    fork(communitiesSaga),
    fork(topCollectorsSaga),
    fork(HotCollectionsSaga),
    fork(RecentlySoldSaga),
    fork(hotBidsSaga),
    fork(TestimonialSaga),
    fork(announcementNewsSaga),
    fork(CoverProfileSaga),
    fork(followsaga),
    fork(RandomSaga),
    fork(museumSaga),
    fork(newListingSaga),
    fork(VisitedNotifSaga),
    fork(HomeMarketPlaceInfoSaga),
    fork(EventsSaga),
    fork(SeedCategoriesSaga),

    //nfts
    fork(NftDetailSaga),
    fork(GetNftHistorySaga),
    fork(EditGallarySaga),
    fork(NotificationSettingsSaga),
    fork(notificationSaga),
    fork(assetPortfolioSaga),
    fork(LastnotificationSaga),
    fork(referralDashboardSaga),
    fork(UploadNftSaga),
    fork(ExploreSaga),
    fork(InspireSaga),
    fork(OrderConfirmationSaga),
    fork(DownloadCertificateSaga),
    fork(ReportNftSaga),
    fork(DeleteNftSaga),
    fork(EditNftSaga),
    fork(NsfwSaga),
    fork(RatingNftSaga),

    //shippingAddress
    fork(ShippingAddressSaga),

    //account
    fork(VerificationFormSaga),
    fork(WalletBalancesSaga),
    fork(GetUserCollectionSaga),
    fork(ToggleLikeSaga),
    fork(ReportUserSaga),
    fork(CheckUserNameSaga),
    fork(DownloadArtistCertificateSaga),
    //collection
    fork(UserCollectionNftSaga),
    fork(SimillarArtworksSaga),
    fork(AllNftCardSaga),
    fork(CreatedNftCardSaga),
    fork(CollectedNftCardSaga),
    fork(CollaborationNftCardSaga),
    fork(FavoritesNftCardSaga),
    fork(ArchiveNftCardSaga),
    fork(ManageMyBidsNftCardSaga),
    fork(AccountCollectionNftsSaga),

    fork(Web3ActionSaga),
    fork(tutorialsSaga),
    fork(museumReleasedSaga),
    fork(museumWelcomeSaga),
    fork(museumCuerrntWeekSaga),
    fork(museumAuctionSaga),
    fork(museumCalendarSaga),
    fork(museumUpcomingSaga),

    fork(TopArtistsSaga),
    fork(MyTransactionsSaga),
    fork(FollowingSaga),
    fork(FollowerSaga),
    fork(ReferralSaga),
    fork(SuiWalletAccountSaga),
    fork(EthWalletAccountSaga),

    fork(GeneralSearchSaga),
    fork(LandingPageSaga),

    //TwitterFarmSaga
    fork(TwitterFarmSaga),
    fork(TwitterPointsSaga),
    fork(TwitterWithdrawalsSaga),
    fork(TwitterStakesSaga),

    //twitter-verify
    fork(VerifyTwittersaga),

    //custom task
    fork(CustomTaskSaga),
  ]);
}
