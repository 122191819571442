// reducer.js  DOWNLOAD_CERTIFICATE
import {
  DOWNLOAD_USER_CERTIFICATE,
  DOWNLOAD_USER_CERTIFICATE_SUCCESS,
  DOWNLOAD_USER_CERTIFICATE_FAILURE,
  RESET_DOWNLOAD_USER_CERTIFICATE,
} from "./actionTypes";

const initialState = {
  loading: false,
  success: false,
  error: null,
  certificate: null,
};
const ArtistCertificate = (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOAD_USER_CERTIFICATE:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case DOWNLOAD_USER_CERTIFICATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        certificate: action.payload,
      };
    case DOWNLOAD_USER_CERTIFICATE_FAILURE:
      state = {
        ...state,
        loading: false,
        success: true,
        error: action.payload,
      };
    case RESET_DOWNLOAD_USER_CERTIFICATE:
      state = {
        ...state,
        loading: false,
        success: false,
        error: null,
        certificate: null,
      };
    default:
      return state;
  }
};

export default ArtistCertificate;
