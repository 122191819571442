// saga.js  DOWNLOAD_CERTIFICATE
import { call, put, takeLatest } from "redux-saga/effects";
import { DOWNLOAD_USER_CERTIFICATE } from "./actionTypes";
import {
  downloadArtistCertificateSuccess,
  downloadArtistCertificateFailure,
} from "./action";
import {
  getDownloadCertificateNft,
  getUserCertificate,
} from "@/config/helpers/backend_helper";
import { api_stage } from "@/config/config";
import { getCookie } from "@/config/helpers/api_helper";

async function downloadCertificate() {
  let baseApi =
    process.env.NEXT_PUBLIC_URL.toLowerCase().includes("stage1") ||
    process.env.NEXT_PUBLIC_URL.toLowerCase().includes("localhost")
      ? api_stage.API_URL_STAGE1
      : process.env.NEXT_PUBLIC_URL.toLowerCase().includes("stage2")
      ? api_stage.API_URL_STAGE2
      : process.env.NEXT_PUBLIC_URL.toLowerCase().includes("stage3")
      ? api_stage.API_URL_STAGE3
      : api_stage.API_URL_MAIN;
  const data = await fetch(`${baseApi}api/Account/CreateUserCertificate`, {
    headers: {
      Authorization: `Bearer ${JSON.parse(getCookie("authentication")).Token}`,
      "Content-Type": "application/json",
    },
  })
    .then((r) => r.blob())
    .then((d) => {
      console.log(window.URL.createObjectURL(d));
      //   fileDownload(d, "certificate.png");
      return window.URL.createObjectURL(d);
    });

  return data;
}

function* DownloadArtistCertificate() {
  try {
    const response = yield call(getUserCertificate);
    console.log(response);
    yield put(downloadArtistCertificateSuccess(response));
    // console.log("response: " + response);
    // console.log("response2: " + url);
  } catch (error) {
    yield put(
      downloadArtistCertificateFailure(error.response.data.Errors[0].Message)
    );
  }
}

function* DownloadArtistCertificateSaga() {
  yield takeLatest(DOWNLOAD_USER_CERTIFICATE, DownloadArtistCertificate);
}

export default DownloadArtistCertificateSaga;
