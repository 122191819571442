// actions.js DOWNLOAD_CERTIFICATE
import {
  DOWNLOAD_USER_CERTIFICATE,
  DOWNLOAD_USER_CERTIFICATE_SUCCESS,
  DOWNLOAD_USER_CERTIFICATE_FAILURE,
  RESET_DOWNLOAD_USER_CERTIFICATE,
} from "./actionTypes";

export const downloadArtistCertificate = () => ({
  type: DOWNLOAD_USER_CERTIFICATE,
});

export const downloadArtistCertificateSuccess = (dataUrl) => ({
  type: DOWNLOAD_USER_CERTIFICATE_SUCCESS,
  payload: dataUrl,
});

export const downloadArtistCertificateFailure = (error) => ({
  type: DOWNLOAD_USER_CERTIFICATE_FAILURE,
  payload: error,
});

export const resetDownloadArtistCertificate = () => ({
  type: RESET_DOWNLOAD_USER_CERTIFICATE,
});
